import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import { getToken } from '../../actions/authActions';
import { disableSort, enableSort, showContextMenu, setState } from '../../actions/UIActions';

import {
  setActiveItem,
  downloadFile,
  sortFolder,
  setLoading,
  fetchDriveStructure,
  sortFile,
} from '../../actions/driveActions';

import { onDragEnter, onDragOver, onDragLeave, onDrop } from '../../utils/upload';
import formattedDate from '../../utils/date';
import { hasPreview } from '../../utils/file';
import { baseUrl } from '../../api';
import { isOwner, getPermission } from '../../utils/permissions';
import Loading from '../common/loading';
import { hasOnlyOffice, hasPreview as itemHasPreview } from '../../utils/file';
import { saveScrollPosition, setScrollPosition } from '../../utils/windowScrollPosition';

class Item extends Component {
  state = {
    isDragOver: false,
    hasThumbnail: false,
    thumbnailLoading: true,
    thumbnailUrl: null,
    thumbnailError: false,
    sortActive: null,
    isShared: false,
  };

  componentDidMount() {
    this.setState({
      isShared: this.props.item.permissions ? true : false,
      hasThumbnail: itemHasPreview(this.props.item),
    });
  }

  handleOnClick = () => {
    const { item } = this.props;
    this.props.dispatch(setActiveItem(item));
  };

  handleOnDoubleClick = () => {
    const { history, item, match, mode, dispatch } = this.props;
    let url;
    let token = match.params.token;

    if (item?.type && hasPreview(item) && token) {
      dispatch(setState({ showPopup: 'preview' }));
      return;
    }

    if (item?.type && token) {
      dispatch(downloadFile(item.id, { item }, token));
      return;
    }

    if (item?.type && hasPreview(item)) {
      dispatch(setState({ showPopup: 'preview' }));
      return;
    }

    if (item?.type && hasOnlyOffice(item)) {
      window.open(`office/${item.id.toString()}/${getToken()}`);
      return;
    }

    if (item?.type) return;

    url = item.id.toString();
    if (mode === 'view') {
      url = `/view-folder/${item.id.toString()}/${token}`;
    }

    history.push(url);
    return;
  };

  handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { dispatch, item } = this.props;

    dispatch(setActiveItem(item));
    dispatch(
      showContextMenu({
        x: e.clientX,
        y: e.clientY,
        item: item,
      })
    );
  };

  handleDragStart = (e) => {
    const { id, item, dispatch } = this.props;

    dispatch(enableSort(item.type === 'file' ? 'file' : 'folder'));
    if (id) e.dataTransfer.setData('itemId', id);
    if (item?.name) e.dataTransfer.setData('name', item.name);
  };

  handleDragLeave = () => {
    this.setState({ sortActive: null });
  };

  handleDragOver = (e, position) => {
    e.preventDefault();
    this.setState({ sortActive: position });
  };

  handleDragEnd = () => {
    this.props.dispatch(disableSort());
    this.setState({ sortActive: null });
  };

  handleDrop = (e) => {
    let { sortActive } = this.state;
    let { item, dispatch, match, UI } = this.props;

    let name = e.dataTransfer.getData('name');
    let id = parseInt(e.dataTransfer.getData('itemId'), 10);
    let sort = parseInt(item.sort, 10);
    let position = sortActive === 'left' ? sort : sort + 1;

    this.setState({ sortActive: null });
    if (item.id === id) return;

    if (UI.sorting === 'folder') {
      dispatch(sortFolder(id, position, name)).then(() => {
        saveScrollPosition();

        dispatch(disableSort());
        dispatch(setLoading());
        dispatch(fetchDriveStructure(match.params.id)).then(() => {
          setScrollPosition();
        });
      });
    }

    if (UI.sorting === 'file') {
      dispatch(sortFile(id, position, name)).then(() => {
        saveScrollPosition();

        dispatch(disableSort());
        dispatch(setLoading());
        dispatch(fetchDriveStructure(match.params.id)).then(() => {
          setScrollPosition();
        });
      });
    }
  };

  handleThumbnailLoaded = (e) => {
    this.setState({ thumbnailLoading: false });
  };

  render() {
    const { isDragOver, hasThumbnail, thumbnailLoading } = this.state;
    const { item, drive, UI, mode } = this.props;
    const { activeItem } = drive;

    const view = UI.driveTypeGrid ? 'grid' : 'list';
    const type = item && item.details && item.details.type ? item.details.type : this.props.type;
    const isActive = activeItem && activeItem.id === item.id ? true : false;
    const sortType = item.type === 'file' ? 'file' : 'folder';

    let isDroppable = mode !== 'view' && type === 'folder'; // TODO: permisions and folder
    if (isDroppable === true) {
      let permissions = item.permissions ? item.permissions : [];
      isDroppable = isOwner(this.props, item) || getPermission(permissions, 'create');
    }

    return (
      <>
        <div
          draggable={!this.state.isShared}
          className={`item item--${type}${isActive ? ' is-active' : ''}${
            isDragOver && isDroppable ? ' is-drag-over' : ''
          }`}
          onDragStart={(e) => this.handleDragStart(e)}
          onDragEnter={isDroppable && !this.props.UI.sorting ? (e) => onDragEnter(this, e) : null}
          onDragOver={isDroppable && !this.props.UI.sorting ? (e) => onDragOver(this, e) : null}
          onDragLeave={isDroppable && !this.props.UI.sorting ? (e) => onDragLeave(this, e) : null}
          onDrop={isDroppable && !this.props.UI.sorting ? (e) => onDrop(this, e) : null}
        >
          {this.props.UI.sorting && !this.state.isShared && (
            <>
              <div
                className={`item-sortable--left ${this.state.sortActive === 'left' ? 'is-active' : ''}`}
                onDrop={sortType === this.props.UI.sorting ? (e) => this.handleDrop(e) : null}
                onDragLeave={sortType === this.props.UI.sorting ? (e) => this.handleDragLeave(e) : null}
                onDragOver={sortType === this.props.UI.sorting ? (e) => this.handleDragOver(e, 'left') : null}
                onDragEnd={sortType === this.props.UI.sorting ? (e) => this.handleDragEnd(e) : null}
              ></div>
              <div
                className={`item-sortable--right ${this.state.sortActive === 'right' ? 'is-active' : ''}`}
                onDrop={sortType === this.props.UI.sorting ? (e) => this.handleDrop(e) : null}
                onDragLeave={sortType === this.props.UI.sorting ? (e) => this.handleDragLeave(e) : null}
                onDragOver={sortType === this.props.UI.sorting ? (e) => this.handleDragOver(e, 'right') : null}
                onDragEnd={sortType === this.props.UI.sorting ? (e) => this.handleDragEnd(e) : null}
              ></div>
            </>
          )}

          <div
            className="item-inner"
            onClick={() => this.handleOnClick()}
            onDoubleClick={() => this.handleOnDoubleClick()}
            onContextMenu={(e) => this.handleContextMenu(e)}
          >
            {view === 'grid' && (
              <>
                {item.details.image_url && (
                  <div className={`item-image`}>
                    <img src={`${baseUrl}${item.details.image_url}`} alt="" />
                    <div className="item-image__description">{item.name}</div>
                  </div>
                )}

                {item.preview_url && hasThumbnail && (
                  <div className={`item-image`}>
                    {thumbnailLoading && <Loading />}
                    <img src={item.preview_url} alt="" onLoad={(e) => this.handleThumbnailLoaded(e)} />
                  </div>
                )}
              </>
            )}

            <div className="item-info">
              {type && (
                <div
                  className={`item-icon item-icon--${type}${
                    item.details.extension ? ` item-ext-${item.details.extension.toLowerCase()}` : ''
                  }`}
                ></div>
              )}
              <div className="item-title">
                {item.name}
                {type === 'file' && `.${item.details.extension}`}
              </div>

              {view === 'list' && (
                <div className="item-additions">
                  <span className="item-additions__date">{formattedDate(item.details.date_created)}</span>
                  <span className="item-additions__date">{formattedDate(item.details.date_updated)}</span>
                </div>
              )}
              {item.quick_access && <div className="item-quick"></div>}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Item);
