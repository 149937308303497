import axios from 'axios';
import fileDownload from 'js-file-download';
import { createNotification } from './UIActions';
import { logout } from './authActions';
import {
  createNewDownload,
  createNewUpload,
  setDownloadState,
  removeDownloadItem,
  setUploadState,
  removeUploadItem,
} from './statusBarActions';
import { setScrollPosition } from '../utils/windowScrollPosition';

export const setActiveItem = (payload) => {
  return {
    type: 'SET_ACTIVE_ITEM',
    payload,
  };
};

export const removeActiveItem = () => {
  return {
    type: 'REMOVE_ACTIVE_ITEM',
  };
};

export const setLoading = () => {
  return {
    type: 'SET_LOADING',
  };
};

// ASYNC ACTIONS
export const fetchDriveStructure = (folderId) => {
  let url = folderId ? `folder/${folderId}` : `folder`;
  if (folderId === 'quick-access') url = `quick-access`;

  return (dispatch) => {
    return axios
      .get(url)
      .then((response) => {
        const data = response?.data;

        // Sort data by name from a-Z
        data.data.folders.sort((a, b) => a.name.localeCompare(b.name));
        data.data.files.sort((a, b) => a.name.localeCompare(b.name));
        if (data.data.share) {
          data.data.share.folders.sort((a, b) => a.name.localeCompare(b.name));
          data.data.share.files.sort((a, b) => a.name.localeCompare(b.name));
        }

        // sort data by sort from 0-9999...
        data.data.folders.sort((a, b) => a.sort - b.sort);
        data.data.files.sort((a, b) => a.sort - b.sort);
        if (data.data.share) {
          data.data.share.folders.sort((a, b) => a.sort - b.sort);
          data.data.share.files.sort((a, b) => a.sort - b.sort);
        }

        dispatch({
          type: 'FETCH_DRIVE_STRUCTURE',
          payload: data,
        });
      })
      .catch(() => {
        dispatch(logout());
      });
  };
};

export const fetchDriveStructureAnonymously = (folderId, token) => {
  const url = folderId ? `folder/${folderId}/share-link?token=${token}` : '';
  return (dispatch) => {
    return axios
      .get(url)
      .then((response) => {
        // Sort data by name from a-Z
        response.data.data.folders.sort((a, b) => a.name.localeCompare(b.name));
        response.data.data.files.sort((a, b) => a.name.localeCompare(b.name));
        response.data.data.share.folders.sort((a, b) => a.name.localeCompare(b.name));
        response.data.data.share.files.sort((a, b) => a.name.localeCompare(b.name));

        // sort data by sort from 0-9999...
        response.data.data.folders.sort((a, b) => a.sort - b.sort);
        response.data.data.files.sort((a, b) => a.sort - b.sort);
        response.data.data.share.folders.sort((a, b) => a.sort - b.sort);
        response.data.data.share.files.sort((a, b) => a.sort - b.sort);

        dispatch({
          type: 'FETCH_DRIVE_STRUCTURE',
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch(logout());
      });
  };
};

export const createFolder = (name, parentId) => {
  const url = `folder`;

  let data = {
    data: {
      folder: {
        name: name,
        parent_id: parentId,
      },
    },
  };

  return (dispatch) => {
    return axios
      .post(url, data)
      .then(() => {
        dispatch(
          createNotification({
            type: 'success',
            message: 'Složka byla úspěšně vytvořena.',
          })
        );
      })
      .catch((error) => {
        let message = 'Složku se nepodařilo úspěšně vytvořit, zkuste to prosím znovu.';
        if (error.response && error.response.status === 403) {
          message = 'K vytvoření složky nemáte dostatečná práva.';
        }

        dispatch(
          createNotification({
            type: 'error',
            message: message,
          })
        );
      });
  };
};

export const editFolder = (name, folderId) => {
  const url = `folder/${folderId}`;

  let data = {
    data: {
      folder: {
        id: folderId,
        name: name,
      },
    },
  };

  return (dispatch) => {
    return axios
      .put(url, data)
      .then((response) => {
        dispatch(
          createNotification({
            type: 'success',
            message: 'Složka byla úspěšně upravena.',
          })
        );
      })
      .catch(() => {
        dispatch(
          createNotification({
            type: 'error',
            message: 'Složku se nepodařilo upravit, zkuste to prosím znovu.',
          })
        );
      });
  };
};

export const editFile = (name, fileId) => {
  const url = `file/${fileId}`;

  let data = {
    data: {
      file: {
        id: fileId,
        name: name,
      },
    },
  };

  return (dispatch) => {
    return axios
      .put(url, data)
      .then((response) => {
        dispatch(
          createNotification({
            type: 'success',
            message: 'Soubor byl úspěšně upraven.',
          })
        );
      })
      .catch(() => {
        dispatch(
          createNotification({
            type: 'error',
            message: 'Soubor se nepodařilo upravit, zkuste to prosím znovu.',
          })
        );
      });
  };
};

export const removeFolder = (folderId) => {
  const url = `folder/${folderId}`;

  return (dispatch) => {
    return axios
      .delete(url)
      .then((response) => {
        dispatch(
          createNotification({
            type: 'success',
            message: 'Složka byla úspěšně odstraněna.',
          })
        );
      })
      .catch(() => {
        dispatch(
          createNotification({
            type: 'error',
            message: 'Složku se nepodařilo odstanit.',
          })
        );
      });
  };
};

export const removeFile = (fileId) => {
  const url = `file/${fileId}`;

  return (dispatch) => {
    return axios
      .delete(url)
      .then((response) => {
        dispatch(
          createNotification({
            type: 'success',
            message: 'Soubor byl úspěšně odstraněn.',
          })
        );
      })
      .catch(() => {
        dispatch(
          createNotification({
            type: 'error',
            message: 'Soubor se nepodařilo odstranit.',
          })
        );
      });
  };
};

export const downloadFile = (fileId, itemInfo, token) => {
  let uniq = Date.now();
  const url = token ? `/file/${fileId}/share-link/download?token=${token}` : `file/${fileId}`;

  return (dispatch) => {
    dispatch(
      createNewDownload({
        uniq,
        itemInfo: { ...itemInfo.item.details },
      })
    );

    return axios({
      method: 'get',
      url,
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        dispatch(
          setDownloadState({
            uniq,
            progressEvent,
          })
        );
      },
    }).then((res) => {
      let filename = res.headers['content-disposition'].split('filename=')[1];
      let mimeType = res.headers['content-type'];
      let fileData = res.data;

      if (itemInfo?.item?.details?.name && itemInfo?.item?.details?.extension) {
        filename = `${itemInfo.item.details.name}.${itemInfo.item.details.extension}`;
      }

      fileDownload(fileData, filename, mimeType);
      dispatch(removeDownloadItem(uniq));
    });
  };
};

export const setQuickAccess = (id, item) => {
  return (dispatch) => {
    return axios({
      method: item.item.quick_access ? 'delete' : 'post',
      url: item.item.type ? `file/quick-access/${id}` : `folder/quick-access/${id}`,
    })
      .then(() => {
        let message = 'Složka byla přidáná do sdílených tabulek.';
        if (item.item.quick_access) message = 'Složka byla odebrána ze sdílených tabulek.';
        if (item.item.type) message = 'Soubor byl přidán do sdílených tabulek.';
        if (item.item.type && item.item.quick_access) message = 'Soubor byl odebrán ze sdílených tabulek.';

        dispatch(
          createNotification({
            type: 'success',
            message,
          })
        );

        dispatch(setLoading());
        dispatch(fetchDriveStructure(item.page.params.id)).then(() => {
          setScrollPosition();
        });
      })
      .catch(() => {
        let message = 'Složku se nepodařilo přidat do sdílených tabulek.';
        if (item.item.quick_access) message = 'Složku se nepodařilo odebrat ze sdílených tabulek.';
        if (item.item.type) message = 'Soubor se nepodařilo přidat do sdílených tabulek.';
        if (item.item.type && item.item.quick_access) message = 'Soubor se nepodařilo odebrat ze sdílených tabulek.';

        dispatch(
          createNotification({
            type: 'error',
            message,
          })
        );
      });
  };
};

export const uploadFiles = (data) => {
  let uniq = Date.now();

  return (dispatch) => {
    dispatch(createNewUpload());
    return axios({
      method: 'POST',
      url: 'file',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        dispatch(
          setUploadState({
            uniq,
            progressEvent: {
              loaded: progressEvent.loaded,
              size: progressEvent.total,
            },
          })
        );
      },
    })
      .then(() => {
        dispatch(removeUploadItem(uniq));
        dispatch(
          createNotification({
            type: 'success',
            message: 'Nahrávání se úspěšně podařilo.',
          })
        );
      })
      .catch((error) => {
        let message = 'Nahrávání se nepodařilo, zkuste prosím znovu.';
        if (error.response && error.response.status === 403) {
          message = 'K nahrávání souborů nemáte dostatečná práva.';
        }
        dispatch(
          createNotification({
            type: 'error',
            message: message,
          })
        );

        dispatch(removeUploadItem(uniq));
      });
  };
};

export const downloadFileAnonymously = (fileId, token, fileName) => {
  const url = `/file/${fileId}/share-link/download?token=${token}`;
  return (dispatch) => {
    return axios({
      method: 'get',
      url: url,
      responseType: 'blob',
    }).then((res) => {
      let filename = fileName || res.headers['content-disposition'].split('filename=')[1];
      let mimeType = res.headers['content-type'];
      let fileData = res.data;

      fileDownload(fileData, filename, mimeType);
    });
  };
};

export const fetchPermissions = (id, type) => {
  let users;
  let permissions;

  if (type && type !== 'folder') {
    permissions = axios.get('permission/file');
    users = axios.get(`file/${id}/permission`);
  } else {
    permissions = axios.get('permission/folder');
    users = axios.get(`folder/${id}/permission`);
  }

  return axios.all([permissions, users]);
};

export const setPermissions = (payload) => {
  let url = '';
  let data = {};

  if (payload.type !== 'file') {
    url = `folder/${payload.id}/permission`;
    data = {
      share_folder: {
        folder_id: payload.id,
        users: payload.permissions,
      },
    };
  } else {
    url = `file/${payload.id}/permission`;
    data = {
      share_file: {
        file_id: payload.id,
        users: payload.permissions,
      },
    };
  }

  return (dispatch) => {
    return axios
      .post(url, { data: data })
      .then((res) => {
        dispatch(
          createNotification({
            type: 'success',
            message: 'Uspěšně jste nastavil práva.',
          })
        );
      })
      .catch(() => {
        dispatch(
          createNotification({
            type: 'error',
            message: 'Práva se nepodařilo správně nastavit.',
          })
        );
      });
  };
};

export const createDocument = (data, type) => {
  const url = type === 'document' ? `office/word` : `office/excel`;

  return (dispatch) => {
    return axios({
      method: 'POST',
      url: url,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(function (data) {
      return data && data.data && data.data.data && data.data.data.file && data.data.data.file.id;
    });
  };
};

export const openEditor = (fileId) => {
  return axios.get(`office/type/${fileId}/view`).then(function (res) {
    // TODO REFACTOR
    var winPrint = window.open('', '', '');
    winPrint.document.write(res.data);
    winPrint.document.close();
  });
};

export const sortFolder = (folderId, position, name) => {
  const url = `folder/${folderId}`;

  let data = {
    data: {
      folder: {
        id: folderId,
        name: name,
        sort: position,
      },
    },
  };

  return (dispatch) => {
    return axios
      .put(url, data)
      .then((response) => {
        dispatch(
          createNotification({
            type: 'success',
            message: 'Složky byly úspěšně přeřazeny.',
          })
        );
      })
      .catch(() => {
        dispatch(
          createNotification({
            type: 'error',
            message: 'Složky se nepodařilo přeřadit, zkuste to prosím znovu.',
          })
        );
      });
  };
};

export const sortFile = (fileId, position, name) => {
  const url = `file/${fileId}`;

  let data = {
    data: {
      file: {
        id: fileId,
        name: name,
        sort: position,
      },
    },
  };

  return (dispatch) => {
    return axios
      .put(url, data)
      .then((response) => {
        dispatch(
          createNotification({
            type: 'success',
            message: 'Soubory byly úspěšně přeřazeny.',
          })
        );
      })
      .catch(() => {
        dispatch(
          createNotification({
            type: 'error',
            message: 'Soubory se nepodařilo přeřadit, zkuste to prosím znovu.',
          })
        );
      });
  };
};

export const getFoldersList = (folderId) => {
  let url = folderId ? `folder/${folderId}` : `folder`;

  return axios.get(url).then((response) => {
    // Sort data by name from a-Z
    response.data.data.folders.sort((a, b) => a.name.localeCompare(b.name));
    response.data.data.files.sort((a, b) => a.name.localeCompare(b.name));
    response.data.data.share.folders.sort((a, b) => a.name.localeCompare(b.name));
    response.data.data.share.files.sort((a, b) => a.name.localeCompare(b.name));

    // sort data by sort from 0-9999...
    response.data.data.folders.sort((a, b) => a.sort - b.sort);
    response.data.data.files.sort((a, b) => a.sort - b.sort);
    response.data.data.share.folders.sort((a, b) => a.sort - b.sort);
    response.data.data.share.files.sort((a, b) => a.sort - b.sort);

    return {
      path: response.data.data.paths,
      share: response.data.data.share.folders,
      folders: response.data.data.folders,
    };
  });
};

export const moveItems = (items, targetFolder) => {
  let url = `change-folder`;

  if (targetFolder === 'root') targetFolder = null;

  const data = {
    data: {
      system_type_ids: [items.id],
      folder_location: targetFolder,
    },
  };

  return axios.post(url, data).then((response) => {
    return response;
  });
};

export const getFileUrl = (fileId) => {
  const url = `/file/${fileId}/create-view`;

  return axios.get(url).then((response) => {
    return response.data;
  });
};
