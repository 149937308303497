import axios from 'axios';

axios.defaults.baseURL = 'http://rp-disk-api.localhost/api/v1/';

// EXTERNITY DISK
if (process.env.REACT_APP_ENVIRONMENT === 'externity') {
    axios.defaults.baseURL = 'https://disk-api.externity.cz/api/v1/';
}

// RP DISK
if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    axios.defaults.baseURL = 'https://rp-disk-api.ex-dev.cz/api/v1/';
}
if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    axios.defaults.baseURL = 'https://disk-api.rpinvest.cz/api/v1/';
}

export const baseUrl = axios.defaults.baseURL;

export function upload(files) {
    return axios({
        method: 'POST',
        url: 'file',
        data: files,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export default {
    upload,
};